// 显示当前时间（年月日时分秒星期）
export function timeFormate () {
  const td = new Date()
  const year = new Date(td).getFullYear()
  const month =
    new Date(td).getMonth() + 1 < 10
      ? '0' + (new Date(td).getMonth() + 1)
      : new Date(td).getMonth() + 1
  const date =
    new Date(td).getDate() < 10
      ? '0' + new Date(td).getDate()
      : new Date(td).getDate()
  const hh =
    new Date(td).getHours() < 10
      ? '0' + new Date(td).getHours()
      : new Date(td).getHours()
  const mm =
    new Date(td).getMinutes() < 10
      ? '0' + new Date(td).getMinutes()
      : new Date(td).getMinutes()
  const ss =
    new Date(td).getSeconds() < 10
      ? '0' + new Date(td).getSeconds()
      : new Date(td).getSeconds()
  const week = new Date(td).getDay()
  const weeks = ['日', '一', '二', '三', '四', '五', '六']
  const getWeek = '星期' + weeks[week]
  const timeStamp = new Date().getTime()
  return {
    date: year + '年' + month + '月' + date + '日',
    time: hh + ':' + mm + ':' + ss,
    week: getWeek,
    timeStamp: timeStamp
  }
}
// 按日期时间格式显示当前时间(yyyy-MM-dd hh:mm:ss)
export function dateFormate (formatStr) {
  const td = new Date()
  const year = new Date(td).getFullYear()
  const month =
    new Date(td).getMonth() + 1 < 10
      ? '0' + (new Date(td).getMonth() + 1)
      : new Date(td).getMonth() + 1
  const date =
    new Date(td).getDate() < 10
      ? '0' + new Date(td).getDate()
      : new Date(td).getDate()
  const hh =
    new Date(td).getHours() < 10
      ? '0' + new Date(td).getHours()
      : new Date(td).getHours()
  const mm =
    new Date(td).getMinutes() < 10
      ? '0' + new Date(td).getMinutes()
      : new Date(td).getMinutes()
  const ss =
    new Date(td).getSeconds() < 10
      ? '0' + new Date(td).getSeconds()
      : new Date(td).getSeconds()
  var str = formatStr
  str = str.replace(/yyyy|YYYY/, year)
  str = str.replace(/MM/, month)
  str = str.replace(/dd|DD/, date)
  str = str.replace(/hh/, hh)
  str = str.replace(/mm/, mm)
  str = str.replace(/ss/, ss)
  return str
}

// 将时间戳转换成日期格式(yyyy-MM-dd hh:mm:ss)
export function toDateTime(timestamp, formatStr) {
  if(timestamp === undefined || timestamp === null| timestamp === '') {    
    return ''
  }
  var date
  //时间戳为10位需*1000，时间戳为13位的话不需乘1000
  if(timestamp > 9999999999) {
    date = new Date(parseInt(timestamp))
  } else {
      date = new Date(parseInt(timestamp) * 1000)
  }
  // var date = new Date(timestamp * 1000);
  const year = date.getFullYear()
  const month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1 
  const dd = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
  // const d = date.getDate()
  const hh = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
  // const h = date.getHours()
  const mm = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()  
  // const m = date.getMinutes()
  const ss = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
  // const s = date.getSeconds()

  var str = formatStr
  str = str.replace(/yyyy|YYYY/, year)
  str = str.replace(/MM/, month)
  str = str.replace(/dd/, dd)
  // str = str.replace(/d/, d)
  str = str.replace(/hh/, hh)
  // str = str.replace(/h/, h)
  str = str.replace(/mm/, mm)
  // str = str.replace(/m/, m)
  str = str.replace(/ss/, ss)
  // str = str.replace(/s/, s)
  return str  
}

export function formatDate (dt, fmt) {
  if(dt === undefined || dt === '') {    
    return ''
  }
  var date = new Date(dt)
  if (/(y+)/.test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  let o = {
      'M+': date.getMonth() + 1,
      'd+': date.getDate(),
      'h+': date.getHours(),
      'm+': date.getMinutes(),
      's+': date.getSeconds()
  };
  for (let k in o) {
      if (new RegExp(`(${k})`).test(fmt)) {
          let str = o[k] + ''
          fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str))
      }
  }
  return fmt
}
function padLeftZero (str) {
  return ('00' + str).substr(str.length)
}

// 当前月第一天
export function getFirstDayOfCurrentMonth () {
  const currentDate = new Date()
  const year = currentDate.getFullYear()
  const month = String(currentDate.getMonth() + 1).padStart(2, '0')
  const firstDay = '01'
  return `${year}-${month}-${firstDay}`
}
// 当前月最后一天
export function getLastDayOfCurrentMonth () {
  // 获取当前日期
  const currentDate = new Date()
  // 获取当前年份
  let year = currentDate.getFullYear()
  // 获取下个月的月份，使用 padStart 在月份小于 10 时在前面补零
  let month = String(currentDate.getMonth() + 2).padStart(2, '0')
  // 如果月份为 '13'，说明当前是12月，需要更新年份并将月份设置为 '01'
  if (month === '13') {
    year = year + 1
    month = '01'
  }
  // 设置每个月的第一天为 '01'
  let firstDay = '01'
  // 构建下个月的第一天的日期字符串，格式为 'YYYY-MM-DD'
  const firstDayOfNextMonth = `${year}-${month}-${firstDay}`
  // 计算当前月的最后一天，通过减去一天的毫秒数来得到
  const lastDayOfMonth = new Date(new Date(firstDayOfNextMonth).getTime() - 86400000)    
  // 将最后一天的日期转换为 ISO 格式，并提取日期部分
  return lastDayOfMonth.toISOString().split('T')[0]
}

// 当前年第一月
export function getFirstMonthOfCurrentYear () {
  const currentDate = new Date()
  const year = currentDate.getFullYear()  
  const month = '01'  
  return `${year}-${month}`
}

// 当前年最后一月
export function getLastMonthOfCurrentYear () {
  const currentDate = new Date()
  const year = currentDate.getFullYear()  
  const month = '12'  
  return `${year}-${month}`
}

// 当前年/月12个月前的年-月
export function getFirstCurrentYearMonth () {
  var data = new Date()
  data.setMonth(data.getMonth() + 1 - 12, 1)
  const year = data.getFullYear()
  var month = data.getMonth() + 1
  month = month < 10 ? '0' + month : month
  return `${year}-${month}`
}

// 当前年-月
export function getCurrentYearMonth () {
  const currentDate = new Date()
  const year = currentDate.getFullYear()
  var month = currentDate.getMonth() + 1
  month = month < 10 ? '0' + month : month
  return `${year}-${month}`
}

// 当前上一月的年-月
export function getLastMonth() {
  let currentDate = new Date()
  let year = currentDate.getFullYear()
  let month = currentDate.getMonth()
  if (month <= 0) {
    year--
    month = 12
  } else if (month < 10) {
    month = '0' + month
  } 
  return `${year}-${month}`
}

// 获取指定日期的下一月（如:dateString=2024-08,得到2024-09）
export function getNextMonth(dateString) {
  var currentDate = new Date(dateString)
  var year = currentDate.getFullYear()
  var month = currentDate.getMonth() + 1
  if (month < 12) {
    month = month + 1
    if (month < 10) {
      month = '0' + month
    }
  } else {
    year = year + 1
    month = '01'
  }
  return `${year}-${month}`
}

// 获取最近12个月，返回'2024年11月'
export function get12Month () {
  var months = []
  var data = new Date()
  data.setMonth(data.getMonth() + 1, 1) //获取到当前月份,设置月份
  for (var i = 0; i < 12; i++) {
    data.setMonth(data.getMonth() - 1) //每次循环一次 月份值减1
    var m = data.getMonth() + 1
    m = m < 10 ? '0' + m : m
    months.push(data.getFullYear() + '年' + m + '月')
  }
  // return months
  return months.reverse()
}
// function timeFormate () {
//   return year + '年' + month + '月' + date + '日' + ' ' + hh + ':' + mm + ':' + ss + ' ' + getWeek
// }
// export { timeFormate, dateFormate }