<template>
  <div class="preview-wrap" id="PreviewWrap">
    <div class="preview-scale" :style="scaleStyle">
      <div class="invoice-preview-wrap">
        <div class="invoice-page">
          <div class="inv-header">
            <div class="inv-title">电子发票（{{ invType }}）</div>
            <div class="inv-id-area">
              <p>
                <span class="label">发票号码:</span>
                <span class="val"></span>
              </p>
              <p>
                <span class="label">开票日期:</span>
                <span class="val"></span>
              </p>
            </div>
          </div>
          <div class="inv-details">
            <div class="section">
              <!-- 企业信息 -->
              <div class="enterprises">
                <div class="left">
                  <div class="label wmr">购买方信息</div>
                  <div class="entp-info">
                    <div class="label">
                      名称:<span class="val">{{ data.ghdwmc }}</span>
                    </div>
                    <div class="label">
                      统一社会信用代码/纳税人识别号:<span class="val f12p">{{ data.ghdwsbh }}</span>
                    </div>
                  </div>
                </div>
                <div class="right">
                  <div class="label wmr" style="border-left: 1px solid #800000">
                    销售方信息
                  </div>
                  <div class="entp-info">
                    <div class="label">
                      名称:<span class="val">{{ data.xhdwmc }}</span>
                    </div>
                    <div class="label">
                      统一社会信用代码/纳税人识别号:<span class="val f12p">{{ data.xhdwsbh }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="table">
                <table style="width: 100%;height: 100%;">
                  <thead style="height: 15px;">
                    <th class="col18 label">项目名称</th>
                    <th class="label">单 位</th>
                    <th class="label">数 量</th>
                    <th class="label">单 价</th>
                    <th class="label">金 额</th>
                    <th class="label">税率/征收率</th>
                    <th class="label">额 税</th>
                  </thead>
                  <tbody>
                    <tr v-for="(item, i) in data.fyxm || []" :key="i">
                      <td class="val" style="text-align: left;">{{ item.spmc }}</td>
                      <td class="val">{{ item.dw }}</td>
                      <td class="val">{{ item.spsl }}</td>
                      <td class="val">{{ item.dj }}</td>
                      <td class="val">{{ item.je }}</td>
                      <td class="val">{{ item.sl }}</td>
                      <td class="val">{{ item.se }}</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td class="label" colspan="2">合计</td>
                      <td></td>
                      <td></td>
                      <td class="val">¥{{ data.hjje }}</td>
                      <td></td>
                      <td class="val">¥{{ data.hjse }}</td>
                    </tr>
                  </tfoot>
                </table>
              </div>
              <div class="c-number">
                <div class="label t1">价税合计（大写）</div>
                <div class="cap_am val">
                  <div class="symbol"></div>
                  {{ formatUpAmt }}
                </div>
                <div class="low_am">
                  <div class="label">（小写）</div>
                  <div class="val">¥{{ data.jshj }}</div>
                </div>
              </div>
              <div class="remark">
                <div class="label wmr">备注</div>
                <div class="val rm-text">
                  <span v-if="data.ghdwdzdh">购买方地址:{{ ghremark.dz }}; 电话:{{ ghremark.dh }};<br /></span>
                  <span v-if="data.ghdwyhzh">购买开户银行:{{ ghremark.khh }}; 银行账号:{{ ghremark.zh }};<br /></span>
                  <span v-if="data.xhdwdzdh">销售方地址:{{ xhremark.dz }}; 电话:{{ xhremark.dh }};<br /></span>
                  <span v-if="data.xhdwdzdh">销买开户银行:{{ xhremark.khh }}; 银行账号:{{ xhremark.zh }};<br /></span>
                  <span v-if="data.fhr">复核人:{{ data.fhr }};</span>
                  <span v-if="data.skr">&nbsp;&nbsp;&nbsp;&nbsp;收款人:{{ data.skr }};</span>
                </div>
              </div>
            </div>
            <div class="footer">
              <div>
                <span class="label">开票人:</span>
                <span class="val">{{ data.kpr }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
function smalltoBIG(n) {
  var fraction = ['角', '分'];
  var digit = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'];
  var unit = [
    ['元', '万', '亿'],
    ['', '拾', '佰', '仟']
  ];
  let head = n < 0 ? '欠' : '';
  n = Math.abs(n);

  let s = '';

  for (let i = 0; i < fraction.length; i++) {
    s += (digit[Math.floor(n * 10 * Math.pow(10, i)) % 10] + fraction[i]).replace(/零./, '');
  }
  s = s || '整';
  n = Math.floor(n);

  for (let i = 0; i < unit[0].length && n > 0; i++) {
    let p = '';
    for (let j = 0; j < unit[1].length && n > 0; j++) {
      p = digit[n % 10] + unit[1][j] + p;
      n = Math.floor(n / 10);
    }
    s = p.replace(/(零.)*零$/, '').replace(/^$/, '零') + unit[0][i] + s;
  }
  return head + s.replace(/(零.)*零元/, '元').replace(/(零.)+/g, '零').replace(/^整$/, '零元整');
}
export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      domClientWidth: 0
    }
  },
  computed: {
    invType() {
      return {
        '81': '增值税专用发票',
        '82': '普通发票'
      }[this.data.fplxdm] || '普通发票'
    },
    ghremark() {
      let obj = {
        dz: '',
        dh: '',
        khh: '',
        zh: ''
      }
      try {
        let arr = this.data.ghdwdzdh.split(' ')
        let arr2 = this.data.ghdwyhzh.split(' ')
        obj.dz = arr[0]
        obj.dh = arr[1]
        obj.khh = arr2[0]
        obj.zh = arr2[1]
      } catch (error) {
        console.log('ghremark  error:', error)
      }
      return obj
    },
    xhremark() {
      let obj = {
        dz: '',
        dh: '',
        khh: '',
        zh: ''
      }
      try {
        let arr = this.data.xhdwdzdh.split(' ')
        let arr2 = this.data.xhdwyhzh.split(' ')
        obj.dz = arr[0]
        obj.dh = arr[1]
        obj.khh = arr2[0]
        obj.zh = arr2[1]
      } catch (error) {
        console.log('xhremark  error:', error)
      }
      return obj
    },
    formatUpAmt() {
      return smalltoBIG(this.data.jshj || '0')
    },
    scaleStyle() {
      let snum = this.domClientWidth / 595
      return {
        'transform': `scale(${snum}, ${snum})`
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.resetDomSize()
    })
    window.addEventListener('resize', this.resetDomSize)
  },
  unmounted() {
    window.removeEventListener('resize', this.resetDomSize)
  },
  methods: {
    resetDomSize() {
      let dom = document.getElementById('PreviewWrap')
      this.domClientWidth = dom.clientWidth
      dom.style.height = `${dom.clientWidth * (396 / 595)}px`
    }
  }
}
</script>

<style scoped>
@font-face {
  font-family: "Simsun";
  src: url("../../assets/typeface/Simsun.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "KaiTi";
  src: url("../../assets/typeface/KaiTi.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.preview-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 100%;
}

.preview-scale {
  transform-origin: center center;
}

.invoice-preview-wrap {
  overflow: hidden;
  position: relative;
  width: 595px;
  height: 396px;
}

.invoice-page {
  display: flex;
  flex-direction: column;
  height: 100%;
  font-family: "楷体", KaiTi, "Microsoft YaHei", sans-serif;
}

.inv-header {
  position: relative;
  display: flex;
  height: 85px;
  justify-content: center;
  /* 在主轴上分配空间 */
  align-items: center;
  /* 垂直居中对齐子组件 */
}

.inv-title {
  position: relative;
  display: flex;
  padding-bottom: 19px;
  font-family: "楷体", KaiTi, "Microsoft YaHei", sans-serif;
  letter-spacing: 2px;
  font-size: 19px;
  color: #800000;
}

.inv-title::before {
  position: absolute;
  content: "";
  height: 1px;
  width: 222px;
  left: 50%;
  bottom: 9px;
  background: #800000;
  transform: translateX(-50%);
}

.inv-title::after {
  position: absolute;
  content: "";
  height: 1px;
  width: 222px;
  left: 50%;
  bottom: 12px;
  background: #800000;
  transform: translateX(-50%);
}

.inv-id-area {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 7px;
  bottom: 25px;
  right: 21px;
  font-size: 9px;
  min-width: 120px;
}

.label {
  color: #800000;
  font-size: 9px;
}

.val {
  font-family: "宋体", Simsun, "Microsoft YaHei", sans-serif;
  color: #000;
  font-size: 9px;
}

.f12p {
  font-size: 12.5px;
}

.wmr {
  display: flex;
  align-items: center;
  height: 100%;
  width: 16px;
  text-align: center;
  border-right: 1px solid #800000;
}

.inv-details {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.inv-details .section {
  display: flex;
  flex-direction: column;
  margin: 0 13px;
  flex: 1;
  border: 1px solid #800000;
}

.inv-details .footer {
  padding-left: 55px;
  height: 44px;
  line-height: 44px;
}

.enterprises {
  display: flex;
  height: 60px;
  border-bottom: 1px solid #800000;
}

.enterprises>div {
  flex-basis: 50%;
  display: flex;
}

.enterprises .entp-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 10px 6px;
}

.table {
  position: relative;
  height: 125px;
  border-bottom: 1px solid #800000;

}

.table .col18 {
  width: 18%;
}

.table td {
  vertical-align: baseline;
}

.table .thead {
  display: flex;
}

.table .tr {
  position: relative;
}

.table .label {
  padding: 5px 5px 0;
  text-align: center;
}

.table .val {
  text-align: center;
}

.table .subtotal {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  font-family: "宋体", Simsun, "Microsoft YaHei", sans-serif;
  color: #000;
  font-size: 9px;
}

.table .total-text {
  position: absolute;
  bottom: 0;
  width: 180px;
  text-align: center;
  letter-spacing: 37px;
  font-size: 9px;
}

.c-number {
  display: flex;
  align-items: center;
  height: 24px;
  line-height: 24px;
  border-bottom: 1px solid #800000;

}

.t1 {
  height: 100%;
  width: 146px;
  border-right: 1px solid #800000;
  text-align: center;
}

.cap_am {
  display: flex;
  align-items: center;
  padding-left: 5px;
  font-size: 9px;
  gap: 2px;
}

.symbol {
  position: relative;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 0.1px solid #000;
}

.symbol::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 0.1px;
  background: #000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
}

.symbol::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 0.1px;
  background: #000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(135deg);
}

.low_am {
  display: flex;
  align-items: center;
  margin-left: auto;
  width: 174px;
  gap: 2px;
}

.remark {
  display: flex;
  flex: 1;
  font-size: 9px;

}

.rm-text {
  padding: 2px;
}
</style>