<template>
  <preview :data="preInvData" />
</template>

<script>
import preview from './preview.vue';
import axios from 'axios'
export default {
  components: {
    preview
  },
  data() {
    return {
      preInvData: {}
    }
  },
  created() {
    const { token, id } = this.$route.query || {}
    if (!token || !id) {
      return
    }
    const baseUrl = process.env.VUE_APP_API
    const headers = {
      'Authorization': token,
      'Content-Type': 'application/json'
    }
    axios.get(`${baseUrl}/thoughtpower-rckm-wld-invoicesvc/api/service/queryInvoiceById/${id}`, { headers }).then(res => {
      if (res.data.body) {
        const data = res.data.body
        data.fyxm = JSON.parse(data.fyxm)
        this.preInvData = data
      }
    })
  }
}
</script>

<style></style>