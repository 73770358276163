<template>
  <div class="wld-visual">
    <iframe
      ref="iframeRef"
      style="width: 100%; height: 100vh"
      src="/cockpit"
      frameborder="0"
      @load="sendToken"
      allow="fullscreen"
    ></iframe>
  </div>
</template>

<script>
export default {
  methods: {
    sendToken() {
      let cookie = this.$cookie.getCookie('nxwebToken')
      const iframe = this.$refs.iframeRef
      if (iframe && iframe.contentWindow) {
        iframe.contentWindow.postMessage(cookie, '*') // 发送 token
      }
    }
  }
}
</script>

<style>
.wld-visual {
  width: 100%;
  height: 100%;
}
</style>