<template>
  <div>
    <!-- 路径 -->
    <div class="path">
      <span><router-link to="/"> 首页</router-link></span>
      &nbsp; &gt; &nbsp;
      <span>数电发票</span>
    </div>
    <div class="card-box">

      <el-tabs v-model="activeName">
        <el-tab-pane label="零售发票" name="first">
        </el-tab-pane>
        <!-- <el-tab-pane label="市场管理发票" name="second">
        </el-tab-pane> -->
      </el-tabs>
      <div class="search-div">
        发票类型：
        <el-radio-group v-model="type">
          <el-radio-button label="1">销项发票</el-radio-button>
          <el-radio-button label="2">进项发票</el-radio-button>
        </el-radio-group>
      </div>
      <el-table v-loading="tableLoading" :data="listData" highlight-current-row stripe border style="width: 100%"
        header-row-class-name="header-row">
        <el-table-column prop="shopId" :label="`${typeText}汇总结算单号`" show-overflow-tooltip min-width="200" />
        <el-table-column prop="tradeAmount" :label="`${typeText}汇总结算金额`" min-width="130" align="right" />
        <el-table-column prop="boothNo" label="销方名称" show-overflow-tooltip min-width="120" />
        <el-table-column prop="boothNo" label="购方名称" show-overflow-tooltip min-width="120" />
        <el-table-column prop="shopName" label="发票单号" show-overflow-tooltip min-width="200" />
        <el-table-column prop="tradeAmount" label="操作" min-width="80" align="center">
          <template #default="scope">
            <el-button size="small">
              查看
            </el-button>
            <el-button size="small" type="danger" @click="handleDelete(scope.$index, scope.row)">
              Delete
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: 'first',
      shopName: '',
      dateValue: [],
      tableLoading: false,
      listData: [{
        shopId: '1'
      }],
      type: '1'
    }
  },
  computed: {
    typeText() {
      return this.type === '1' ? '销售' : '采购'
    }
  },
  methods: {
    getSummaryData() {

    }
  }
}
</script>

<style scoped>
.search-div {
  display: flex;
  align-items: center;
}
</style>