<template>
  <!-- 图片轮播 -->
  <div ref="ref_ad" class="ad" :style="{height:adHeight + 'px'}"> 
    <!-- <img :src="require('@/assets/images/ad11.png')" />    -->
    <v-homesimage />
  </div>
  
  <div v-if="isLogin" class="pb_15">
    <div class="flex-between mb_15">
      <div class="title">年交易统计</div>
      <!-- <router-link to="#" class="a_tit">更多 &gt;</router-link> -->
    </div>    
    <el-card :body-style="{ padding: '0px' }" class="card1"> 
      <!-- <div class="flex-between">
        <div style="width: 45%; padding: 20px;"><v-homeshopname /></div>
        <div style="width: 55%; padding: 20px;"><v-hometrade /></div>
      </div>-->
      <el-row :gutter="10">
        <el-col :sm="{span:11}">
          <v-homeshopname />
        </el-col>
        <el-col :sm="{span:13}">
          <v-hometrade />
        </el-col>
      </el-row>
    </el-card>
  </div>

  <div v-if="isLogin" style="margin-bottom: 30px;">
    <div class="flex-between mb_15">
      <div class="title">今日实时交易</div>
      <!-- <router-link to="#" class="a_tit">更多 &gt;</router-link> -->
    </div>
    <el-card :body-style="{ padding: '0px' }" class="card1"> 
      <v-Top ref="indexTop" />
    </el-card>
  </div>
</template>

<script>
import homesimage from '@/components/home-image'
import homeshopname from '@/components/home-shopname'
import hometrade from '@/components/home-trade'
import Top from '@/components/top'
export default {
  components: {
    'v-homesimage': homesimage,
    'v-homeshopname': homeshopname,
    'v-hometrade': hometrade,
    'v-Top': Top
  },
  data () {
    return {
      isLogin: false,
      adHeight: 500 // 轮播图高（宽*500/1270）
    }
  },
  // 接收父组件传值
  // props: {
  //   windowWidth: Number
  // },
  mounted () {    
    this.isLogin = this.$cookie.getCookie('nxwebToken') ? true : false
    // console.log('this.isLogin=', this.isLogin)
    // this.getWindowWidth()
    // 监听window窗口大小变化
    // window.addEventListener('resize', this.getWindowWidth)
  },
  methods: {
    // getWindowWidth () {
    //   const width = this.$refs.ref_ad.offsetWidth
    //   this.adHeight = 700
    //   console.log('adwidth=', width, this.adHeight)
    // },
  }
}
</script>

<style>
.body_bgcolor {
  background-color: #F2F3F7
}
.ad {
  margin: 20px 0;
}
.ad .el-carousel__container {
  height: 500px;
}
.ad img{
  width: 100%;  
  height: 100%;
}
/* .ad .img-bg {
  width: 100%;
  height: 99.9%;
  border: 1px solid #999;
  background: url('../assets/images/ad11.png') center no-repeat;
} */
.message {
  margin: 32px 0;
}
.message .el-carousel__container {
  height: 28px;
}
.message-title {
  margin: 0 16px;
  font-size: 18px;
  font-weight: bold;
  float: left;
}
.message-title a.tit {
  font-size: 18px;
  font-weight: bold;  
}
.message span {
  font-size: 13px;
  color: #999;
}
.message-list {
 line-height: 28px; 
}
.card1{
  /* width: 99%;   */
  padding: 3% 3%;
  /* padding: 30px 50px;   */
}
.card1 img {
  width: 100%;
  height: 172px;
}
.card1-body{
  padding: 15px;
  line-height: 180%;
  font-size: 14px;
}
.card1-title{
  padding: 15px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}
.nlist1{
  padding: 32px 24px; 
  height: 230px; 
}
.nlist1 ul {
  margin:0;
  padding:0;
}
.nlist1 li {
  list-style-type: none;
  padding: 5px 0;
  letter-spacing: 1px;  
}
.nlist1 li a {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.nlist1 li a span {
  padding-right: 5px;
  font-size: 14px;
  font-weight: bold;
  color: #ccc;
}
.nlist1 li a .a1  {
  padding: 0;
  font-weight: bold;
  color: #1D5CB9;
}
.nlist1 li time {
  width: 100px;
  padding-top: 5px;
  font-size: 12px;
  color: #aaa;
  text-align: right;
}
.message a {
  display:block;
  font-size:15px;
}
</style>