<template> 
  <div class="login-mian" :style="{height:clientHeight + 'px'}">  
    <div class="login-logo"><img src="@/assets/images/loginlogo.png" /></div>
      <div class="login1">
        <v-login />
      </div>  
  </div>
</template>

<script>
import vLogin from '@/components/vlogin'
export default {
  components: {
    'v-login': vLogin
  },
  data () {
    return {
      clientHeight: 700,
    }
  },
  mounted () {
    window.addEventListener('resize', this.screenAdapter)
    this.screenAdapter()
  },
  // unmounted ()
  beforeUnmount() {
    window.removeEventListener('resize', this.screenAdapter)
  },
  methods: {
    screenAdapter () {
      if (window.innerHeight) {
        this.clientHeight = window.innerHeight
      } else if ((document.body) && (document.body.clientHeight)) {
        this.clientHeight = document.body.clientHeight
      }
    }
  }
}
</script>

<style>
.login-mian {
  width: 100%;
  height: 100%;
  background: url('~@/assets/images/back.jpg')  center repeat;
  background-size:cover; 
}
.login-logo {
 width: 100%;
 padding: 155px 0 15px 0; 
 text-align: center;
}
.login-logo img {
 width: 65px;
 height: 65px;
}
.login1 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>