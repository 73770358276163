<template>
  <div>
    <!-- 路径 -->
    <div class="path">
      <span><router-link to="/"> 首页</router-link></span>
      &nbsp; &gt; &nbsp;
      <span>数电发票</span>
    </div>
    <div class="card-box">

      <el-tabs v-model="activeName">
        <el-tab-pane label="零售发票" name="first">
        </el-tab-pane>
        <!-- <el-tab-pane label="市场管理发票" name="second">
        </el-tab-pane> -->
      </el-tabs>
      <div class="search-div">
        发票类型：
        <el-tag type="success" size="large">销项发票</el-tag>
        <el-tag type="primary" size="large" style="margin-left: 8px;">进项发票</el-tag>
      </div>
      <el-row>
        <el-col>
          <el-table :data="pageData.list">
            <el-table-column class-name="merge-cell">
              <el-table-column prop="id" label="销项发票" align="center" class-name="suc-column">
                <el-table-column prop="ghdwmc1" label="购买方" show-overflow-tooltip />
                <el-table-column prop="xhdwmc1" label="销售方" show-overflow-tooltip />
                <el-table-column prop="jshj1" label="价税合计" />
                <el-table-column prop="hjse1" label="税额" />
                <el-table-column prop="fphm1" label="发票单号" show-overflow-tooltip>
                  <template #default="scope">
                    <el-link v-if="scope.row.status1 === '0'" type="primary"
                      @click.prevent="preview(scope.row.id1)">待开票</el-link>
                    <el-link v-else type="primary" :href="scope.row.fphm1">{{
                      scope.row.fphm1 }}</el-link>
                  </template>
                </el-table-column>
                <el-table-column prop="kprq1" label="开票时间" show-overflow-tooltip />
                <el-table-column label="发票下载" show-overflow-tooltip width="120px">
                  <template #default="scope">
                    <el-link v-if="scope.row.pdfUrl1" :href="scope.row.pdfUrl1" type="primary">PDF</el-link>
                    <el-link v-if="scope.row.ofdUrl1" :href="scope.row.ofdUrl1" type="primary">OFD</el-link>
                    <el-link v-if="scope.row.xmlUrl1" :href="scope.row.xmlUrl1" type="primary">XML</el-link>
                  </template>
                </el-table-column>
              </el-table-column>
              <el-table-column prop="id" label="进项发票" align="center" class-name="pri-column">
                <el-table-column prop="ghdwmc2" label="购买方" show-overflow-tooltip />
                <el-table-column prop="xhdwmc2" label="销售方" show-overflow-tooltip />
                <el-table-column prop="jshj2" label="价税合计" />
                <el-table-column prop="hjse2" label="税额" />
                <el-table-column prop="fphm2" label="发票单号" show-overflow-tooltip>
                  <template #default="scope">
                    <el-link v-if="scope.row.status2 === '0'" type="primary"
                      @click.prevent="preview(scope.row.id2)">待开票</el-link>
                    <el-link v-else type="primary" :href="scope.row.fphm2">{{
                      scope.row.fphm2 }}</el-link>
                  </template>
                </el-table-column>
                <el-table-column prop="kprq2" label="开票时间" show-overflow-tooltip />
                <el-table-column label="发票下载" show-overflow-tooltip width="120px">
                  <template #default="scope">
                    <el-link v-if="scope.row.pdfUrl2" :href="scope.row.pdfUrl2" type="primary">PDF</el-link>
                    <el-link v-if="scope.row.ofdUrl2" :href="scope.row.ofdUrl2" type="primary">OFD</el-link>
                    <el-link v-if="scope.row.xmlUrl2" :href="scope.row.xmlUrl2" type="primary">XML</el-link>
                  </template>
                </el-table-column>
              </el-table-column>
            </el-table-column>
          </el-table>
          <div class="pagination">
            <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="pageData.pageNumber" :page-sizes="[10, 15, 20, 30, 40]" :page-size="pageData.pageSize"
              layout="total, sizes, prev, pager, next, jumper" :total='pageData.totalCount'>
            </el-pagination>
          </div>
        </el-col>
      </el-row>
    </div>
    <el-dialog title="预览发票" v-model="dialogPreVisible" width="60%" destroy-on-close align-center>
      <invoice :data="preInvData" />
    </el-dialog>
  </div>
</template>

<script>
import invoice from './preview.vue'
export default {
  components: {
    invoice
  },
  data() {
    return {
      activeName: 'first',
      shopName: '',
      dateValue: [],
      tableLoading: false,
      listData: [],
      pageData: {
        list: [],
        pageNumber: 1,
        pageSize: 10,
        totalCount: 0,
        totalPage: 0,
      },
      type: '1',
      currentPage: 1,
      pagesize: 10,
      dialogPreVisible: false,
      preInvData: {}
    }
  },
  computed: {
    typeText() {
      return this.type === '1' ? '销售' : '采购'
    }
  },
  created() {
    this.getListData()
  },
  methods: {
    async getListData() {
      var en = {
        position: 'invoice_queryTradeInvoiceList',
        paramMap: {
          orgId: '9ce5a4d757f9d6434008b1449d20a641',
        },
        pageable: {
          pageNumber: this.pageData.pageNumber,
          pageSize: this.pageData.pageSize
        }
      }
      const { data: res } = await this.$http.post(this.$api.querySqlByPage, en)
      if (res && res.body && res.body) {
        this.pageData = res.body
      }

    },
    // pageSize 新每页条数改变时触发(每页下拉显示数据)
    handleSizeChange(size) {
      this.pageData.pageSize = size
      this.getListData()
    },
    // 新当前页改变时触发(点击第几页)
    handleCurrentChange(currentPage) {
      this.pageData.pageNumber = currentPage
      this.getListData()
    },
    async preview(id) {
      const { data: res } = await this.$http.get(`${this.$api.invpreview}/${id}`)
      if (res && res.body) {
        res.body.fyxm = JSON.parse(res.body.fyxm)
        this.preInvData = res.body
        this.dialogPreVisible = true
      }
    }
  }
}
</script>

<style scoped>
.search-div {
  display: flex;
  align-items: center;
}
</style>
<style>
.merge-cell {
  display: none !important;
}

.suc-column {
  background: var(--el-color-success-light-9) !important;
}

.suc-primary {
  background: var(--el-color-primary-light-9) !important;
}
</style>