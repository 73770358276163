<!--布局页面-->
<template>
  <div>
    <!-- <v-head /> -->
    <div class="head">
      <el-row :gutter="0">
        <el-col :xs="0" :sm="0" :md="0" :lg="1" :xl="4"></el-col>      
        <!-- <el-col :sm="24" :md="24" :lg="22" :xl="16" >
          <div class="head-logo">
            <div class="logo">
              <router-link to="/">
                <img src="@/assets/images/logo.png" />
              </router-link>
              <img src="@/assets/images/logo1.png" style="margin-left: 50px;" />
              <span style="color: #1D2088">云南建投建筑机械有限公司</span>
            </div>
            <div class="logo-1">
              <div class="head-login">
                <span v-if="myToken">
                  欢迎，<span class="uname">{{uname}}</span> 光临！ |                  
                  <a @click="onLogout" class="logout">安全退出</a>
                </span>
              </div>
              <div>
                联系人:蒋红水，联系电话:18314451898
              </div>
            </div>
          </div>
        </el-col> -->
        <el-col :xs="24" :sm="24" :md="8" :lg="7" :xl="5" >
          <div class="head-logo">
            <div class="logo">
              <img src="@/assets/images/logo1.png" />              
              <router-link to="/">
                <img src="@/assets/images/logo.png" style="margin-left: 50px;height: 80px;" />
              </router-link>
            </div>            
          </div>
        </el-col>
        <el-col :xs="24" :sm="12" :md="9" :lg="8" :xl="5" >          
          <div class="logo-text">              
            云南建投建筑机械有限公司
          </div>
        </el-col>
        <el-col :xs="24" :sm="12" :md="7" :lg="7" :xl="6" >
          <div class="logo-1">
            <div class="head-login">
              <span v-if="myToken">
                欢迎，<span class="uname">{{uname}}</span> 光临！ |                  
                <a @click="onLogout" class="logout">安全退出</a>
              </span>
            </div>
            <div>
              联系人:蒋红水，联系电话:18314451898
            </div>
          </div>
        </el-col>
        <el-col :xs="0" :sm="0" :md="0" :lg="1" :xl="4"></el-col>
      </el-row>
      <el-row :gutter="0" style="background-color:#007932;">
        <el-col :sm="0" :md="0" :lg="1" :xl="4"></el-col>      
        <el-col :sm="24" :md="24" :lg="22" :xl="16" >          
          <div class="menu">
            <el-menu :default-active="$route.path" mode="horizontal"
              background-color="#007932" text-color="#fff" active-text-color="#ffd04b" style="width: 100%"> 
              <template v-for="(menu,index) in menuData" :key="index" >
                  <el-sub-menu v-if="(menu.qx === 0 || (myToken && menu.qx === 1) || (!myToken && menu.qx === 2)) && menu.menuItem.length > 0" :index="menu.id">
                    <template #title>
                      <span>{{menu.name}}</span>
                    </template>
                    <div v-for="item1 in menu.menuItem" :key="item1.index">
                      <el-sub-menu v-if="item1.menuItem.length > 0" :index="item1.id">
                        <template #title>{{item1.name}}</template>
                        <div v-for="item2 in item1.menuItem" :key="item2.index">
                          <el-sub-menu v-if="item2.menuItem.length > 0" :index="item2.id">
                            <template #title>{{item2.name}}</template>
                            <el-menu-item :index="item3.path" v-for="item3 in item2.menuItem" :key="item3.index" @click="menuClick(item3)">{{item3.name}}</el-menu-item>
                          </el-sub-menu>
                          <el-menu-item v-else :index="item2.path" @click="menuClick(item2)">{{item2.name}}</el-menu-item>
                        </div>
                      </el-sub-menu>
                      <el-menu-item v-else :index="item1.path" @click="menuClick(item1)">{{item1.name}}</el-menu-item>
                    </div>
                  </el-sub-menu>
                  <el-menu-item v-else-if="(menu.qx === 0 || (myToken && menu.qx === 1) || (!myToken && menu.qx === 2))" :index="menu.path" @click="menuClick(menu)">
                    {{menu.name}}                    
                    <!-- <a v-if="menu.path.startsWith('https://')" :href="menu.path" target="_blank" >{{menu.name}}</a>
                    <span v-else>{{menu.name}}</span> -->
                  </el-menu-item>
              </template>
            </el-menu>
          </div>
        </el-col>
        <el-col :sm="0" :md="0" :lg="1" :xl="4"></el-col>
      </el-row>
    </div>
    <el-row :gutter="0" :class="[$route.path == '/index' ? 'home_bgcolor' : 'page_bgcolor']">
      <el-col :sm="0" :md="0" :lg="1" :xl="4"></el-col>
        <el-col :sm="24" :md="24" :lg="22" :xl="16">
          <router-view :windowWidth="windowWidth" />
        </el-col>
      <el-col :sm="0" :md="0" :lg="1" :xl="4"></el-col>
    </el-row>
    <div :class="[$route.path == '/index' ? 'foot' : 'foot1']"> 
      <el-row :gutter="0">
        <el-col :sm="0" :md="0" :lg="1" :xl="4"></el-col>
        <el-col :sm="24" :md="24" :lg="22" :xl="16">
          <div class="foot-div">
            <div>
              Copyright © {{year}} 云南建投建筑机械有限公司  滇ICP备2024016852号-3  
              <!-- All Rights Reserved ©  <br />
              版权所有 ©  -->
            </div>            
            <!-- <div>  
              <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010902003472" target="_blank">沪公网安备 31010902003472号 &nbsp;沪ICP备2023006978号-1</a>
            </div>-->
          </div>
        </el-col>
        <el-col :sm="0" :md="0" :lg="1" :xl="4"></el-col>
      </el-row>
    </div>
  </div>  

  <!-- <el-dialog title="用户登录" v-model="dialogLoginVisible" :close-on-click-modal="false" :width="dialogLoginWidth">
    <v-login  style="margin: 10px 4%;" />
  </el-dialog> -->
</template>

<script>
// import vLogin from '@/components/vlogin'
export default {
  // name: 'home',
  // components: {
  //   'v-login': vLogin
  // },
  data () {
    return {
      windowWidth: 1200, // 当前屏宽
      myToken: '',
      uname: '',
      year: new Date().getFullYear(),      
      menuData: [
        {
          id: '100',
          name: '首页',
          path: '/index',
          qx: 0,
          // icon: 'HomeFilled',
          menuItem:[]
        },
        {
          id: '901',
          name: '数据大屏',
          qx: 1,
          path: '/visual',
          menuItem:[]
        },
        {
          id: '200',
          name: '关于我们',
          path: '/about',
          qx: 0,
          menuItem:[]
        },
        {
          id: '210',
          name: '进销存报表',
          path: '/login',
          qx: 2,
          menuItem: []
        },
        {
          id: '210',
          name: '进销存报表',
          path: '/login',
          qx: 1,
          menuItem: [
            {
              id: '310',
              name: '收支交易明细',
              path: '/dealdetail',
              qx: 1,
              menuItem: []
            },
            {
              id: '400',
              name: '市场采购报表',
              qx: 1,
              menuItem: [
                {
                  id: '401',
                  name: '市场采购汇总',
                  path: '/purchasesummary',
                  menuItem: []
                },
                {
                  id: '402',
                  name: '市场采购明细',
                  path: '/purchasedetail',
                  menuItem: []
                }]
            },
            {
              id: '500',
              name: '商户供货报表',
              qx: 1,
              menuItem: [{
                id: '501',
                name: '商户供货汇总',
                path: '/sellsummary',
                menuItem: []
              },
              {
                id: '502',
                name: '商户供货明细',
                path: '/selldetail',
                menuItem: []
              }]
            },
            {
              id: '600',
              name: '商户收款报表',
              qx: 1,
              menuItem: [{
                id: '601',
                name: '商户收款汇总',
                path: '/paysummary',
                menuItem: []
              },
              {
                id: '602',
                name: '商户收款明细',
                path: '/paydetail',
                menuItem: []
              }]
            },
            {
              id: '700',
              name: '交易日报',
              qx: 1,
              menuItem: [{
                id: '701',
                name: '交易日报汇总',
                path: '/daysummary',
                menuItem: []
              },
              {
                id: '702',
                name: '交易日报明细',
                path: '/daydetail',
                menuItem: []
              }]
            },
            {
              id: '800',
              name: '交易月报',
              qx: 1,
              menuItem: [{
                id: '801',
                name: '交易月报汇总',
                path: '/monthsummary',
                menuItem: []
              },
              {
                id: '802',
                name: '交易月报明细',
                path: '/monthdetail',
                menuItem: []
              }]
            },
          ]
        },
        // {
        //   id: '220',
        //   name: '管理驾驶舱',
        //   qx: 0,
        //   path: 'https://',
        //   menuItem:[]
        // },
        {
          id: '902',
          name: '市场零售业务发票',
          qx: 1,
          path: '/retail-invoice',
          menuItem:[]
        },
        {
          id: '900',
          name: '进入管理系统',
          qx: 0,
          path: 'https://www.wuliduo.com/apps/ThoughtPower-rckm-wld-WldSupplyOnlineApp/#/index',
          menuItem:[]
        },
        {
          id: '1000',
          name: '安全管理',
          qx: 0,
          path: 'https://user.cli.im/vip/oIQQOf',
          menuItem:[]
        }        
      ]
      // dialogLoginVisible: false
    }
  },
  mounted () {  
    this.myToken = this.$cookie.getCookie('nxwebToken') 
    this.uname = this.$cookie.getCookie('uname') 
    this.getWindowWidth()
    // 监听window窗口大小变化
    window.addEventListener('resize', this.getWindowWidth) 
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.getWindowWidth)
  },
  methods: {
    getWindowWidth () {
      if (window.innerWidth) {
        this.windowWidth = window.innerWidth
      } else {
        this.windowWidth = document.body.clientWidth
      }
    },
    menuClick(item) {
      if (item.path.startsWith('https://') || item.path.startsWith('http://')) {
        //页面重定向
        // window.location.href = item.path
        window.open(item.path, '_blank')
      } else {
        this.$router.push({ path: item.path })
      }
    },
    // onLogin () {
    //   this.dialogLoginVisible = true
    // },
    async onLogout () {
      this.$confirm('您确定要退出登录吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        // await this.$http.post(this.$api.logout)
        // this.$store.dispatch('setToken', null)
        this.$cookie.clearCookie('nxwebToken')        
        this.$cookie.clearCookie('uname')
        this.$nextTick(() => {
          this.myToken = ''
        })
        //this.$router.replace('/index')
        this.$router.push({ path: '/index', query: { refresh: new Date().getTime() } })
      })
    }
  },
  watch: {
    // $route(to, from) {
    //   console.log('to, from', to, from)
    //   this.activeMenu = to.path; // 监听路由变化，保持activeMenu最新
    // }
  }
}
</script>

<style>
.head{
  /* padding: 5px 0; */
  color: #333;  
}
/* .head1 .el-col {
  padding: 0;  
} */
.head-logo {  
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}
.head-logo .uname {
  font-weight: bold;
  color: #007932;
}
.logo {
  display: flex; 
  flex-wrap: nowrap; 
	align-items:center;
  padding: 10px 0;
}
.logo img{
  width: auto;
  height: 100px;
  /* max-width: 280px;
  height: auto; */
}
.logo span{
  margin: 0 15px;
  font-size: 28px;
  font-weight: 800;
  color: #444;
  /* color: #007932; */
  letter-spacing: 2px;  
}
.logo-text{
  display: flex;
  align-items: center;
  /* justify-content: center; 水平居中 */
  /* height: 100px; */
  height: 100%;
  font-size: 26px;
  font-weight: 800;
  color: #1D2088;  
}
.logo-1 {
  text-align: right;
  color: #999;
}
.menu {
  display: flex;
  /* justify-content: flex-end; */
  justify-content: center;
  align-items: center;  
}
.menu a {
  color: #fff;
}
.el-menu-item {  
  padding: 0 29px;
}
.el-menu-item, .el-sub-menu span {
  font-size: 16px;
  font-weight: 600;
  line-height: 50px;
}
.el-menu--horizontal {
  border-bottom: none;
}
.el-sub-menu .el-menu-item {
  background-color: #999;
}

.foot {
  padding: 30px 0;
  /* background-color:#79bbff; */
  background-color: #F2F3F7;
  color: #333;
  font-size: 13px;
  text-align: center;
}
.foot1 {
  padding: 30px 0;
  /* background-color:#79bbff; */
  background-color: #fff;
  color: #333;
  font-size: 13px;
  text-align: center;
}
.foot-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.foot-div img {
  width: 120px;
  height: auto;
}.foot-div div {
  line-height: 200%;
}
.head-login {
  padding: 5px;
  height: 50px;
  text-align: right;
  vertical-align: top;
}
</style>
