import axios from 'axios'
import Cookie from '@/utils/cookie'
import router from '@/router'
import { ElLoading, ElMessage} from 'element-plus'

axios.interceptors.request.use(config => {
  // 设置以 form 表单的形式提交参数，如果以 JSON 的形式提交表单，可忽略
  // if (config.method === 'post') {
  //   // JSON 转换为 FormData
  //   const formData = new FormData()
  //   Object.keys(config.data).forEach(key => formData.append(key, config.data[key]))
  //   config.data = formData
  // }
  // const token = Storage.session.get('kmust-webtoken') 
  const token = Cookie.getCookie('nxwebToken') 
  // console.log('token=', token) 
  if (token) {    
    // config.headers.Authorization = 'Bearer ' + ui.token
    config.headers.Authorization = token
    // config.headers.Accept = 'application/json'
    // config.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
  }
  return config
}, err => {
  return Promise.reject(err)
})

axios.interceptors.response.use((response) => {
  // 判断一下响应中是否有 token，如果有就直接使用此 token 替换掉本地的 token。你可以根据你的业务需求自己编写更新 token 的逻辑
  // var token = response.headers.retoken
  // if (token) {
  //   Storage.set('xucmstoken', token)
  // }
  // console.log('response=', response.data.state.code) 
  if (response.data.state.code === 10003) {
    ElMessage({
      message: response.data.state.message + '，请重新登录。',
      type: 'warning',
    })
    // 清除token
    Cookie.clearCookie('nxwebToken') 
    router.replace({
      path: '/login',      
      // 登录成功后跳入浏览的当前页面
      // query: { redirect: router.currentRoute.fullPath }
    })         
  } else 
  return response
}, (err) => {
  //请求错误 
  if (err.response) {
    // console.log('err.response=', err.response)
    switch (err.response.status) {
      case 400:
        // 其它exe错误（一般性）
        if (err.response.data.code === 3000) {
          ElMessage({
            message: err.response.data.data,
            type: 'error',
          })          
        }
        break   
      // case 401:
      //   // 这里写清除token的代码
      //   router.replace({
      //     path: '/login',
      //     // 登录成功后跳入浏览的当前页面
      //     query: { redirect: router.currentRoute.fullPath }
      //   })
      //   break
      case 500:
        ElMessage({
          message: err.response.statusText,
          type: 'error',
        })
        break 
      default:
        return err.response
    }
  }
  return Promise.reject(err)
})

class http {
  static get (url, params) {
    return axios.get(url, params)
  }

  static post (url, params) {
    return axios.post(url, params)
  }

  static put (url, params) {
    return axios.put(url, params)
  }

  static down (url, params) {
    return axios({
      method: 'get',
      url: url,
      params: params,
      responseType: 'blob'
    })    
  }

  static postdown (url, params) {
    return axios.post(url, params, {
      responseType: 'blob'
      // responseType: "arraybuffer"
    })
  }

  static get1 (url, params) {    
    const loading = ElLoading.service({
      lock: true,
      text: '正在加载中...',
      // spinner: 'el-icon-loading',
      // background: 'rgba(0, 0, 0, 0.7)'
    })
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url,
        params
      }).then((data) => {
        resolve(data)
      }).catch(err => {
        reject(err)        
      }).finally(() => {
        loading.close()
      })
    })    
  }

  static post1 (url, params) {    
    const loading = ElLoading.service({
      lock: true,
      text: '正在加载中...',
      // spinner: 'el-icon-loading',
      // background: 'rgba(0, 0, 0, 0.7)'
    })
    return new Promise((resolve, reject) => {
      axios.post(url, params)
      // axios({
      //   method: 'post',
      //   url,
      //   params
      // })
      .then((data) => {        
        resolve(data)
      }).catch(err => {
        reject(err)        
      }).finally(() => {
        loading.close()
      })
    })    
  }
}
export default http