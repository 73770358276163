// api路径 '/prod-api'
const baseUrl = process.env.VUE_APP_API

// 汇总接口
const querySql = baseUrl + '/thoughtpower-rckm-wld-sqlcomputesvc/api/service/querySql'
// 明细分页接口
const querySqlByPage = baseUrl + '/thoughtpower-rckm-wld-sqlcomputesvc/api/service/querySqlByPage'

// 导出接口
const queryExport = baseUrl + '/thoughtpower-rckm-wld-sqlcomputesvc/api/service/queryAndExport'

// 轮播图片接口1
const homepageImage = baseUrl + '/thoughtpower-rckm-wld-homepageimagesvc/api/object/homepageImage/lookup'
// 轮播图片接口2
const attachment = baseUrl + '/thoughtpower-rckm-wld-attachmentsvc/api/object/attachment/lookup'

// 登录接口
const servicelogin = baseUrl + '/thoughtpower-rckm-wld-useraccountsvc/api/service/login'

const invpreview = baseUrl + '/thoughtpower-rckm-wld-invoicesvc/api/service/queryInvoiceById'


export default {
  querySql,
  querySqlByPage,
  queryExport,
  homepageImage,
  attachment,
  servicelogin,
  invpreview
}
