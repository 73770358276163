<!--关于我们情页-->
<template>
  <!-- 路径 -->
  <div class="path">
    <span><router-link to="/"> 首页</router-link></span> 
    &nbsp; &gt; &nbsp;
    <span>关于我们</span>
    <!-- <span v-for="(item,index) in pathList" :key="index">
      &nbsp; &gt; &nbsp;
      <router-link v-if="item.categoryPath" :to="item.categoryPath"> {{item.name}}</router-link>
      <span v-else>{{item.name}}</span>
    </span> -->
  </div>
  <div class="card-box" >  
    <!-- 内容 -->
    <div class="newslist">
      <div class="news_title">关于我们</div> 
      <div class="news_content">
        <br/>
        <div style="text-align:center;">
          <img src="@/assets/images/about.jpg" alt="">
        </div>        
        <div>
          <p>
            五里多农博广场位于昆明市官渡区，是一个集蔬菜、水果、粮油、肉类、调味品等各类生鲜产品和特色生活配套于一体的大型农贸市场。‌‌
          </p>
          <p>
          地理位置与规模‌：
          <br/>
          五里多农博广场位于昆明市官渡区民航路与吴井路交叉口，占地面积约1.65万平方米，商业使用面积达3.4万平方米，拥有600多个摊位和近300间商铺。
          </p>
          <p>
          ‌商品种类与价格‌：
          <br/>
          市场内商品种类丰富，价格便宜，吸引了大量顾客。蔬菜、水果、粮油、肉类等大部分商品都是产地直供，省去了中间环节，确保物美价廉。
          例如，石林老品种人参果10块钱3公斤，山地大白菜2元钱一公斤，香蕉4块钱一公斤，漾濞核桃10块钱一公斤，车厘子便宜的只要25元一公斤。
          </p>              
          <p>
          ‌历史背景与发展‌：
          <br/>
          五里多农博广场的前身是东站农贸市场，随着城市发展进行搬迁和改造，于2016年正式营业。市场保留了东站老商户和商业模式，逐渐形成品类多样的市场布局。
          </p>
          <p>
            ‌人流量与商户情况‌：
            <br/>
            市场日均人流量近5万人，春节期间客流量更是达到8万人左右，商户销量翻倍。为了确保供应充足，商户和管理方加大了货物储备量，并加强了巡查力度。
          </p>
          <p>
            ‌设施与服务‌：
            <br/>
            市场内设施齐全，环境整洁。昆仑燃气昆明分公司进行了“瓶改管”项目，确保用气安全和成本降低，提升了商户和顾客的便利性。
            五里多农博广场不仅是一个购物的好去处，更是昆明市民生活中不可或缺的一部分，提供了丰富多样的商品和便捷的服务。
            <br/>
            <br/>
            联系方式：
            <br/>
            地址：昆明官渡区云南省昆明市官渡区五里多农博广场E区32号西南方向30米
            <br/>
            电话：0871-63366949
            <br/>
            所属分类：农副产品、水产市场
          </p>
        </div>
      </div>            
    </div>  
  </div>
</template>

<script>
// import {formatDate} from '@/utils/datetime_utils.js'
export default {
  data () {
    return {
    }
  },
  mounted () {   
  },
  methods: { 
  }
}
</script>

<style>
.news_title {
  display: block;
  padding: 1.0rem 0;
  font-size: 20px;
  font-weight: bold;
  color:#333;
  text-align: center;
  border-bottom: 1px solid #e8e8e8;  
}
.news_tit {
  display: block;
  padding: 0.5rem 0;
  font-size: 13px;
  color:#999;
  text-align: center;
}
.news_tit span {
  padding: 0 15px;
}
.news_content {
    display: block;
    /* padding: 16px; */
    text-align: justify !important;
    line-height: 180%;
    min-height: 400px;
    color:#666;
  }
  .news_content img {
    max-width: 100%;
    height: auto;
  }
  .news_bottom {
    padding: 16px;
    /* border: 1px solid #e8e8e8; */
    line-height: 30px;  
  }
  .newslist img {
    max-width: 100%;
    height: auto;
  }

</style>
