<!--用户登录-->
<template>
  <div class="login_box">
    <div class="login_tit">密码登录</div>
    <el-form ref="formRef" :model="form" :rules="rules" class="form">      
      <el-form-item prop="username">
        <img src="@/assets/images/user.png" />
        <el-input v-model="form.username" placeholder="请输入手机号（用户名）"></el-input>
      </el-form-item>
      <el-form-item prop="password"> 
        <img src="@/assets/images/pwd.png" />       
        <el-input v-model="form.password" show-password placeholder="请输入密码"></el-input>
      </el-form-item>
      <!-- <el-form-item prop="vcode">
        <el-input v-model="form.vcode" placeholder="请输入验证码" prefix-icon="CircleCheck" class="vcode" style="width:140px"></el-input>
        <img :src="vcodeImg" alt="看不清?点击更换" onclick="this.src = this.src + '?'" class="vcode" />
      </el-form-item> -->
      <el-button type="primary" @click="onLogin">登 录</el-button>
    </el-form>
    <!-- <div class="login-bottom"><a  href="#"> 忘记密码</a> &nbsp; | &nbsp; <router-link to="/regist">新用户注册</router-link></div> -->
  </div>
</template>

<script>
export default {
  name: 'login',
  data () {
    return {
      myToken: '',
      // vcodeImg: this.$api.validatecode,      
      vcodeImg: '',
      form: {
        username: '',
        password: ''
        //vcode: ''
      },
      userInfo:{
        name: '',        // 用户名或昵称
        isLogin: false,  //是否已登录
        token: ''
      },
      rules: {
        username: [
          { required: true, message: '用户名不能为空', trigger: 'blur' }
          // { min: 6, max: 20, message: '长度在 6 到 20 个字符', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '密码不能为空', trigger: 'blur' }
          // { min: 6, max: 20, message: '长度在 6 到 20 个字符', trigger: 'blur' }
        ]
        // vcode: [
        //   { required: true, message: '验证码不能为空', trigger: 'blur' }
        // ]
      }
    }
  },
  //登录后要去的路径地址
  //props:['toUrl'],
  mounted () {    
  },
  methods: {
    onLogin () {
      this.$refs.formRef.validate(async (valid) => {
        if (valid) {
          var en = {
            account: this.form.username,
            password: this.form.password
          }
          const { data: res } = await this.$http.post(this.$api.servicelogin, en) 
          // console.log('login=', res, en)
          if (!res || !res.body || !res.body.token) {
            this.$message.error('登录失败，请核对用户名或密码')
            return
          }
          if (res.state.code === 10000) {
            if (res.body !== -1) {
              //登录成功获取用户信息
              var tok = res.body.token
              this.$cookie.setCookie({nxwebToken: tok, uname: this.form.username}, 2)              
              this.$router.replace('/index')
            } else {
              this.$message.error('登录失败，请重新登录。')
              this.form.username = '',
              this.form.password = ''
            }
            // this.$nextTick(() => {
            //   this.myToken = tok
            // })            
          } else {
            this.$message.error(res.state.message)
          }
        } else {
          this.$message.error('验证没通过。')
          return false
        }
      })       
      // this.$parent.$parent.$parent.$parent.$parent.$parent.dialogLoginVisible = false
      // this.$router.replace('/') 
    },
    
    // saveUserInf (token, udata, cdata) {
    //   var userInfo = {
    //     uid: udata.id,  
    //     cid: cdata == null ? '' : cdata.id, // 用户企业id
    //     cname : cdata ==  null ? '' : cdata.name, // 用户企业中的姓名
    //     ctype : cdata ==  null ? '' : cdata.type, // 用户企业中的用户类型
    //     uname: udata.username, // 用户名(登录)
    //     name: udata.nickName !== '' && udata.nickName !== null ? udata.nickName : udata.uname, // 用户名或昵称(显示)
    //     token: token    
    //   }
    //   // 广播事件 用户登录成功传参昵称或用户名
    //   bus.emit('userLoginEvent', userInfo) 
    //   this.$message.success('登录成功')
    //   // this.$global.setUI(userInfo)
    //   this.$store.dispatch('setUser', userInfo)
    //   // 登录页登录后跳转
    //   if (!this.toUrl) {        
    //     // console.log('route1111=', this.$route.query.redirect) 
    //     var url = this.$route.query.redirect 
    //     if (url) {
    //       this.$router.push(url)
    //       // this.$router.push({           
    //       //   path: url            
    //       // })
    //     } else {
    //       this.$router.replace('/member/index') 
    //     }        
    //   } else if (this.toUrl === '/') {
    //     // 子组件修改父组件数据(关闭登录对话框)
    //     this.$parent.$parent.$parent.$parent.$parent.$parent.dialogLoginVisible = false
    //     this.$router.replace('/') 
    //   }
    //   else {
    //     // 子组件修改父组件数据(关闭登录对话框)
    //     this.$parent.$parent.$parent.$parent.$parent.$parent.dialogLoginVisible = false
    //     setTimeout(() => {   
    //       // 登录对话框登录后跳转新页
    //       let route = this.$router.resolve({ path: this.toUrl })
    //       window.open(route.href, '_blank')       
    //     }, 1500)
    //   }
    // }
  }
}
</script>

<style scoped>
.login_box {
  width: 420px;
  height: 265px;
  /* width: 323px;
  height: 184px; */
  /* padding: 30px 50px 50px 50px; */
  /* border: 1px solid #9FB5CA; */
  /* background-color: #F4F7F9;  */
  background-color: #fff;
  border-radius: 8px;
}
.login_box .vcode{
  vertical-align:middle;  
}
.login_tit {  
  margin: 30px 50px 0 50px;
  font-size: 15px;
  color:#409EFF;  
  line-height: 40px;
  /* text-align: center; */
  border-bottom: 2px solid #ddd;
  margin-bottom: 20px;
}
.form {
  margin: 0 50px 40px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.el-input{
  width: 230px;
  /* font-size: 16px; */
}
.el-button{
  width: 100%;
  font-size: 16px;
}
.login-bottom {
  margin-top: 16px;
  text-align:right
}
.login_box img {
  margin-right: 10px;
  width: 20px;
  height: 20px;  
}
</style>