<!--商户经营商品品类占比饼图-->
<template>
  <div class="com-container">
    <div ref="ratio_ref" class="com-chart"></div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      chartInstance: null
    }
  },
  mounted () {
    this.initChart()
    this.getData()
    // 监听窗口变化，发生变化调用this.screenAdapter函数
    window.addEventListener('resize', this.screenAdapter)
    // 在页面加载完成时主动进行屏幕适配
    this.screenAdapter()
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.screenAdapter)
  },
  methods: {
    async getData () {
      var en = {
        position: "merchantTrade_queryMerchantCount"        
      }
      const { data: res } = await this.$http.post(this.$api.querySql, en) 
      // console.log('经营商品品类统计启用的商户数量', res)
      if (res.state.code === 10000) {
        this.getEchartData(res.body)     
      } else {
        this.$message.error(res.state.message)
      }
    },
    getEchartData (list) {
      var list1 = []
      list.forEach(p => {        
        list1.push({
          name: p.businessName,
          value: p.merchantCount
        })        
      })
      list1 = [
        {
          value: 176,
          name: '蔬菜'
        },
        {
          value: 62,
          name: '肉类'
        },
        {
          value: 32,
          name: '蛋禽'
        }
      ]
      this.updateEchart(list1)
    },
    initChart () {
      this.chartInstance = this.$echarts.init(this.$refs.ratio_ref)
      // 对图表初始化配置的控制
      const initOption = {        
        title: {
          text: '商户入驻比率',
          left: '3%'
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        // legend: {
        //   orient: 'vertical',
        //   left: 20,
        //   bottom: 40
        // },
        series: [
          {
            name: '商户入驻比率',
            type: 'pie',
            radius: '55%',
            center: ['50%', '55%'],            
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            label: {              
              fontSize: 15,
              formatter: '{b}: {c} ({d}%)'
            }
          }
        ]
      }
      this.chartInstance.setOption(initOption)
    },
    // 更新图表
    updateEchart (datas) {
      const dataOption = {
        series: [
          {
            data: datas
          }
        ]
      }
      this.chartInstance.setOption(dataOption)
    },
    // 当浏览器的大小发生变化的时候，会调用的方法，来完成屏幕的适配
    screenAdapter () {
     this.chartInstance.resize()
    }
  }
}
</script>

<style>

</style>